
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// Custom Components
import MapViewer from 'components/housing-rental/MapViewer/MapViewer';
import PhotoGallery from 'components/common/PhotoGallery/PhotoGallery';

// Models
import { Listing, listingTypeMap } from 'models/Listing';
// API
import { fetchOneListing } from 'api/listings';



const ListingDetails: FC = (): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  // Listings data
  const { listingId } = useParams<{ listingId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listing, setListing] = useState<Listing | null>(null);

  useEffect(() => {
    document.title = `${listing?.title}`
  }, [listing]);

  useEffect(() => {
    const getListing = async (listingId: string) => {
      setIsLoading(true);
      const data = await fetchOneListing(listingId);
      setListing(data);
      setIsLoading(false);
    }
    getListing(listingId as string);
  }, [listingId]);

  // Save to Wishlist
  const onSavedToWishlist = (id: string) => {
    console.log(`Save ${id} to Wishlist`);
    const updatedListing = { ...listing, isSaved: !listing?.isSaved };
    setListing(updatedListing as Listing);
  };

  if (isLoading) {
    return (
      <Container sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 64px)', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!listing) {
    return <>Not Found</>;
  }

  return (
    <Container maxWidth="lg" sx={{ minHeight: 'calc(100vh - 64px)' }}>
      {listing && (
        <>
          {/* Photo */}
          {/* {listing.pictures.map((photo, index) => <div>{photo}</div>)} */}
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box>
                <PhotoGallery photos={listing.pictures} />
              </Box>

              {/* Listing Details */}
              <Box mt={3}>
                <Typography variant="h4" component="h4">{listing.title}</Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon fontSize='medium' sx={{mr: 1}} /> {listing.address.street}, {listing.address.zipCode} {listing.address.city}, {listing.address.country}
                </Typography>
                {/* <Typography variant="subtitle2">{listing.price}</Typography> */}
              </Box>

              <Stack mt={2} direction="row" spacing={1}>
                <Chip label={listingTypeMap(listing.type)} variant="outlined" />
                <Chip label={`${listing.area} m²`}  variant="outlined" />
                <Chip label={`${listing.rooms} Room${listing.rooms>1 ? 's' : ''}`}  variant="outlined" />
                <Chip label={`${listing.maxTenants} people`}  variant="outlined" />
                {listing.floor && <Chip label={`${listing.floor} floor`}  variant="outlined" />}
              </Stack>

              <Box mt={7}>
                <div>
                  <h2>About this place</h2>
                  <span>{listing.description}</span>
                </div>
              </Box>

              {/* <Box
                className="lisings"
                sx={{
                  flex: '1 1 0',
                  overflow: 'scroll',
                }}
              >
                <Box sx={{ flex: '1 1 0', width: '100%', marginLeft: '20px' }}>
                  <MapViewer listings={[listing]} onSaved={onSavedToWishlist} />
                </Box>
              </Box> */}
            </Grid>
            <Grid item xs={12} md={5}>
              RIGHT
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ListingDetails;
