import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import Link from '@mui/material/Link';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTranslation } from 'react-i18next';

import LanguageChanger from 'components/common/LanguageChanger/LanguageChanger';
import Profile from 'components/common/Profile/Profile';

interface Props {
  children: React.ReactElement;
}

// For scrolling actions of the navbar
// function ScrollHandler(props: Props) {
const ScrollHandler: FC<Props> = (props: Props): React.ReactElement => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 80,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    color: trigger ? 'primary' : 'transparent',
    transition: trigger ? '0.3s' : '0.5s',
  });
}

const NavBar: FC = (props): JSX.Element => {
  // i18n
  const { t } = useTranslation();
  const pages = [
    { 
      "name": t('navbar.housingRental'),
      "path": "#/housing-rental",
      "disabled": false
    },
    {
      "name": t('navbar.secondHand'),
      "path": "#/second-hand",
      "disabled": true
    }
  ];

  // anchors

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <ScrollHandler {...props}>
        <AppBar
          position="fixed"
          elevation={4}
          sx={{
            transition: "all 0.5s ease-in-out"
          }}
        >
          <Container maxWidth={false}>
            <Toolbar disableGutters>
              {/* LEFT SIDE (Desktop) */}

              {/* Logo */}
              {/* <FavoriteIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
              <Typography
                variant="h4"
                noWrap
                component="a"
                href="/"
                color="inherit"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'Dancing Script, cursive', // change font family here
                  // fontFamily: 'Helvetica',
                  fontWeight: 700,
                  letterSpacing: '.0.5rem',
                  textDecoration: 'none',
                }}
              >
                OneKo
              </Typography>
              {/* Menu */}
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.path}
                    href={page.path}
                    disabled={page.disabled}
                    color="inherit"
                    sx={{ transition: "none" }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>

              {/* LEFT SIDE (Mobile) */}

              {/* Menu */}
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                  disableScrollLock={true}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.path}
                      disabled={page.disabled}
                    >
                      <Link href={page.path} underline='none'>
                        {page.name}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* Logo */}
              {/* <FavoriteIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
              <Typography
                variant="h4"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'Dancing Script, cursive', // change font family here
                  // fontFamily: 'Helvetica',
                  fontWeight: 700,
                  letterSpacing: '.0.5rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                OneKo
              </Typography>

              {/* RIGHT SIDE */}

              {/* Language Changer */}
              <Box sx={{ flexGrow: 0, mx: 3 }}>
                <LanguageChanger />
              </Box>

              {/* Profile */}
              <Box sx={{ flexGrow: 0 }}>
                <Profile />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ScrollHandler>
      <Toolbar />
    </>
  );
}

export default NavBar;
