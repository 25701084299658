import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Load translations
import ZH_CN from "i18n/locales/zh-cn.json";
import EN from "i18n/locales/en.json";

const resources = {
  zh_cn: {
    translation: ZH_CN
  },
  en: {
    translation: EN
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    // lng: "zh_cn", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
