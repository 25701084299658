import React, { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';

import { useTranslation } from 'react-i18next';

const LanguageChanger: FC = (): JSX.Element => {

  const { t, i18n } = useTranslation();

  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null);

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleCloseLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(null);
  };
  const handleChangeLanguage = (
    event: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    i18n.changeLanguage(option);
    setAnchorElLanguage(null);
  };

  return (
    <>
      <Tooltip title={t("navbar.language")}>
        <IconButton color="inherit" size="medium" sx={{ p: 0 }} onClick={handleOpenLanguageMenu}>
          <TranslateIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="lang-menu-appbar"
        anchorEl={anchorElLanguage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElLanguage)}
        onClose={handleCloseLanguageMenu}
        disableScrollLock={true}
      >
        <MenuItem onClick={(event) => handleChangeLanguage(event, 'zh_cn')}>
          <Typography textAlign="center">中文</Typography>
        </MenuItem>
        <MenuItem onClick={(event) => handleChangeLanguage(event, 'en')}>
          <Typography textAlign="center">English</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default LanguageChanger;