import { FC } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface LocationSelectorProps {
  sx?: SxProps<Theme>;
  options: any[];
  handleChangeLocation: (newLocation: string) => void;
}

const LocationSelector: FC<LocationSelectorProps> = ({ sx=[], options, handleChangeLocation }) => {
  // i18n
  const { t } = useTranslation();

  const handleChange = (
    event: any,
    newOption: any | null,
  ) => {
    console.log(newOption);
    if (newOption === null) handleChangeLocation('');
    else handleChangeLocation(newOption.Gemeinde_name);
  };

  return (
    <Autocomplete
      id="location-selector"
      sx={[
        {
          width: 240
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      size="small"
      options={options}
      // groupBy={(option) => option.admin_name}
      getOptionLabel={(option) => option.Gemeinde_name}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={t('housingRental.selectDistrict')} />}
      // renderGroup={(params) => (
      //   <li key={params.key}>
      //     <GroupHeader>{params.group}</GroupHeader>
      //     <GroupItems>{params.children}</GroupItems>
      //   </li>
      // )}
    />
  );
}

export default LocationSelector
