import React from 'react';
import './App.scss';

// Theme
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Router
import {
  // createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';

// Snackbar
import { SnackbarProvider } from 'context/snackbar';

// Components
import AppLayout from 'components/layouts/AppLayout';
import Home from 'pages';
import HousingRental from 'pages/housing-rental';
import ListingDetails from 'pages/housing-rental/listing-details';

const theme = createTheme({
  palette: {
    primary: {
      main: "#065A82",
    },
    secondary: {
      main: "#1C7293"
    },
    info: {
      main: "#9EB3C2"
    }
  },
});

const router = createHashRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "housing-rental",
        element: <HousingRental />
      },
      {
        path: "housing-rental/:listingId",
        element: <ListingDetails />
      }
    ]
  }
]);

function App() {
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
