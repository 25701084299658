import { FC, useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import { Box, Slider, Popover } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface PriceSelectorProps {
  sx?: SxProps<Theme>;
  priceRange: [number, number];
  minPrice: number;
  maxPrice: number;
  handleChangePriceRange: (newPriceRange: [number, number]) => void;
}

function valuetext(value: number) {
  return `${value} Euros`;
}

const PriceSelector: FC<PriceSelectorProps> = ({ sx=[], priceRange, minPrice, maxPrice, handleChangePriceRange }): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  // Popover
  const [anchorElPrice, setAnchorElPrice] = useState<null | HTMLElement>(null);

  const handleOpenPriceMenu = (event: React.MouseEvent<HTMLElement>) => {
    // Reset value to priceRange
    resetValue();
    setAnchorElPrice(event.currentTarget);
  };
  const handleClosePriceMenu = () => {
    setAnchorElPrice(null);
  };

  const [value, setValue] = useState<[number, number]>([0, 0]);
  useEffect(() => {
    setValue(priceRange);
  }, [priceRange]);

  const resetValue = () => {
    setValue(priceRange);
  }

  const handleConfirmChange = () => {
    handleChangePriceRange(value);
  }

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleOpenPriceMenu}
        sx={[
          { textTransform: 'none' },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        { t('housingRental.price') }
      </Button>
      <Popover
        open={Boolean(anchorElPrice)}
        anchorEl={anchorElPrice}
        onClose={handleClosePriceMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width={280} mx={4} my={4}>
          <Slider
            size='medium'
            getAriaLabel={() => 'Price range'}
            value={value}
            min={minPrice}
            max={maxPrice}
            onChange={
              (event: Event, newValue: number | number[]) => {
                setValue(newValue as [number, number]);
              }
            }
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            sx={{ mb: 2 }}
          />

          <Box display='flex' alignItems='center'>
            <FormControl fullWidth sx={{ mr: 2 }} variant="standard">
              <InputLabel htmlFor="min-price">{ t('housingRental.minPrice') }</InputLabel>
              <Input
                id="min-price"
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                value={value[0]}
                // onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangePriceRange([Number(event.target.value), priceRange[1]])}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue([Number(event.target.value), priceRange[1]])}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </FormControl>

            <span>-</span>

            <FormControl fullWidth sx={{ ml: 2 }} variant="standard">
              <InputLabel htmlFor="max-price">{ t('housingRental.maxPrice') }</InputLabel>
              <Input
                id="max-price"
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                value={value[1]}
                // onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangePriceRange([priceRange[0], Number(event.target.value)])}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue([priceRange[0], Number(event.target.value)])}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </FormControl>
          </Box>
        </Box>

        <Box display='flex' justifyContent='flex-end' mx={4} mb={3}>
          <Button
            variant="outlined"
            size="medium"
            onClick={resetValue}
            sx={[
              { textTransform: 'none' },
              { mr: 2 },
            ]}
          >
            { t('common.reset') }
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={handleConfirmChange}
            sx={[
              { textTransform: 'none' },
            ]}
          >
            { t('common.confirm') }
          </Button>
        </Box>

      </Popover>
    </>
  );
}

export default PriceSelector;
