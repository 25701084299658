import 'leaflet/dist/leaflet.css';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './MapViewer.scss'

// Leaflet
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup
} from 'react-leaflet'
import L from 'leaflet';
// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Components
import ListingItem from 'components/housing-rental/ListingItem/ListingItem';

// Types
import { Listing } from 'models/Listing';


interface MapViewerProps {
  listings: Listing[];
  onSaved?: (id: string) => void;
};

// const defaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowSize: [41, 41],
// });

const customIcon = (price: number) => L.divIcon({
  className: 'price-marker',
  html: `<div class="price-marker-content">€ ${price}</div>`,
  iconSize: [70, 40],
  iconAnchor: [35, 0]
});

const MapViewer: FC<MapViewerProps> = ({ listings=[], onSaved }): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  const [center, setCenter] = useState<[number, number]>([52.520008, 13.404954]);

  useEffect(() => {
    if (listings.length > 0) {
      setCenter([listings.reduce((acc, listing) => acc + listing.address.latitude, 0) / listings.length, listings.reduce((acc, listing) => acc + listing.address.longitude, 0) / listings.length]);
    } else {
      setCenter([52.520008, 13.404954]);
    }
  }, [listings]);



  return (
    <MapContainer
      // set center to center of all listings received
      center={center}
      zoom={12}
      scrollWheelZoom={true}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {listings.map((listing: Listing) => 
        <Marker position={[listing.address.latitude, listing.address.longitude]} icon={customIcon(listing.price)}>
          <Popup>
            <ListingItem sx={{ minWidth: '260px' }} listing={listing} onSaved={onSaved} />
          </Popup>
        </Marker>
      )}
    </MapContainer>
  )
};

export default MapViewer;
