import api from '.';
import { Listing } from 'models/Listing';

// Fetch all listings
export const fetchListings = async (): Promise<Listing[]> => {
  const response = await api.get('/listings');
  return response.data;
}

// Fetch a single listing by id
export const fetchOneListing = async (id: string): Promise<Listing> => {
  const response = await api.get(`/listings/${id}`);
  return response.data;
}