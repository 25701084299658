import { FC, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { red } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
// Types
import { Listing, listingTypeMap } from 'models/Listing';
// Context
import { SnackbarContext } from 'context/snackbar';

interface ListingItemProps {
  listing: Listing;
  sx?: SxProps<Theme>;
  onSaved?: (id: string) => void;
};

const ListingItem: FC<ListingItemProps> = ({ sx=[], listing, onSaved }): JSX.Element => {

  // Navigation
  const navigate = useNavigate();

  const { addAlert } = useContext(SnackbarContext);

  const handleClick = (id: string) => navigate(`/housing-rental/${id}`);

  const handleClickSave = (event: React.MouseEvent, id: string) => {
    event.stopPropagation(); // Stop event propagation
    // TODO: call API to save listing
    
    if (!listing.isSaved) addAlert({
      message: `${id} saved to wishlist`,
      severity: 'success',
      duration: 3000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center'}
    });
    else addAlert({
      message: `${id} removed from wishlist`,
      severity: 'info',
      duration: 3000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center'}
    });

    onSaved?.(id);
  };

  // Image carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageChange = (event: React.MouseEvent, direction: 'left' | 'right') => {
    event.stopPropagation(); // Stop event propagation
    if (direction === 'left') {
      setCurrentImageIndex(oldIndex => oldIndex > 0 ? oldIndex - 1 : listing.pictures.length - 1);
    } else {
      setCurrentImageIndex(oldIndex => (oldIndex + 1) % listing.pictures.length);
    }
  };

  return (
    <Card
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        { maxWidth: 450 },
        {
          boxShadow: 1,
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: 6,
          },
          cursor: 'pointer'
        },
      ]}
      onClick={() => handleClick(listing._id)}
    >
      <Box position="relative">
        {/* {listing.pictures.map((picture, index) => (
          <CardMedia
            key={picture}
            component="img"
            height="240"
            image={picture}
            alt={`picture ${index}`}
            sx={{
              position: index === currentImageIndex ? 'relative' : 'absolute',
              opacity: index === currentImageIndex ? 1 : 0,
              visibility: index === currentImageIndex ? 'visible' : 'hidden',
              transition: 'opacity 0.3s ease-in-out'
            }}
          />
        ))} */}
        {listing.pictures.map((picture, index) => (
          <Box
            key={picture}
            sx={{
              position: index === currentImageIndex ? 'relative' : 'absolute',
              opacity: index === currentImageIndex ? 1 : 0,
              visibility: index === currentImageIndex ? 'visible' : 'hidden',
              transition: 'opacity 0.3s ease-in-out',
              paddingTop: '72.99%', // 1/1.37 aspect ratio
              width: '100%', // for the aspect ratio to take effect
              '& img': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            }}
          >
            <CardMedia
              component="img"
              image={picture}
              alt={`picture ${index}`}
            />
          </Box>
        ))}

        <Stack position="absolute" sx={{ left: 2, top: "50%", transform: 'translateY(-50%)' }}>
          <IconButton aria-label="previous picture" onClick={(event) => handleImageChange(event, 'left')}>
            <ArrowBackIosIcon sx={{ color: 'white' }} />
          </IconButton>
        </Stack>
        <Stack position="absolute" sx={{ right: 2, top: "50%", transform: 'translateY(-50%)' }}>
          <IconButton aria-label="next picture" onClick={(event) => handleImageChange(event, 'right')}>
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
          </IconButton>
        </Stack>

        <Stack position="absolute" direction="row" sx={{ bottom: 4, left: "50%", transform: 'translateX(-50%)' }}>
          {listing.pictures.map((_, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                display: 'inline-block',
                p: 0.5,
                mx: 0.4,
                borderRadius: '50%',
                backgroundColor: index === currentImageIndex ? 'white' : 'grey.500',
                transition: 'background-color 0.3s ease-in-out',
              }}
            />
          ))}
        </Stack>
        <Stack position="absolute" top={2} right={2}>
          <IconButton aria-label="save to wishlist" onClick={(event) => handleClickSave(event, listing._id)}>
            <FavoriteIcon
              sx={{
                color: listing.isSaved ? red[600] : ''
              }}
            />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        <Typography minHeight={60} mb={1} fontSize={16} fontWeight={800} component="div">
          {listing.title}
        </Typography>
        <Box mb={2}>
          <Typography fontSize={16} fontWeight={800} color="primary" component="span">
            € {listing.price}
          </Typography>
          <Typography fontSize={14} color="text.secondary" component="span">
            &nbsp;/ month
          </Typography>
        </Box>

        <Typography fontSize={14} color="text.secondary" component="span">
          {listingTypeMap(listing.type)} • {listing.area} m² • {listing.rooms} {`Room${listing.rooms>1 ? 's' : ''}`}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ListingItem;
