import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-Type': 'application/json',
    // 'Api-Key': process.env.REACT_APP_API_KEY,
  },
  // ...other global configurations
});

export default instance;