import { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

interface PhotoGalleryProps {
  photos: string[];
}

const PhotoGallery: FC<PhotoGalleryProps> = ({ photos }): JSX.Element => {

  // Lightbox
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const toggleOpen = (state: boolean) => () => setOpen(state);
  const updateIndex = ({ index: current }: { index: number }) => setIndex(current);


  const [position, setPosition] = useState<
    "top" | "bottom" | "start" | "end"
  >("bottom");
  // const [width, setWidth] = useState(120);
  // const [height, setHeight] = useState(80);

  const slides = photos.map((photo) => ({
    src: photo,
    // width: 'min(4320px,100%)',
    // height: ,
  }));

  return (
    <>
      <Box 
        sx={{ 
          width: '100%', 
          paddingTop: '66.66%', // 3:2 aspect ratio
          backgroundImage: `url(${photos[0]})`, 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center center',
          // cursor: 'pointer',
          display: 'flex', // Added
          alignItems: 'flex-end', // Added
          justifyContent: 'flex-start', // Added
        }}
      >
        <Button
          size="small"
          variant="contained" 
          sx={{ 
            mx: 3,
            my: 2,
            color: 'black',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          onClick={() => setOpen(true)}
        >
          Photos
        </Button>
      </Box>
      
      <Lightbox
        open={open}
        close={toggleOpen(false)}
        // index={index}
        slides={slides}
        // on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        // controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        carousel={{ finite: true, preload: photos.length }}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          position: "bottom",
          // width: 180,
          // height,
          border: 0,
          borderRadius: 4,
          padding: 4,
          gap: 16,
          showToggle: true,
        }}
      />
    </>
  )
};

export default PhotoGallery;