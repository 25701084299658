
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

// Custom Components
import LocationSelector from 'components/housing-rental/LocationSelector/LocationSelector';
import PriceSelector from 'components/housing-rental/PriceSelector/PriceSelector';
import ViewSelector from 'components/housing-rental/ViewSelector/ViewSelector';
import MapViewer from 'components/housing-rental/MapViewer/MapViewer';
import ListingItem from 'components/housing-rental/ListingItem/ListingItem';

// Models
import { Listing } from 'models/Listing';
// API
import { fetchListings } from 'api/listings';



const HousingRental: FC = (): JSX.Element => {
  // i18n
  const { t } = useTranslation();
  useEffect(() => { document.title = `${t('housingRental.tabTitle')}` }, [t]);

  // Listings data
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listings, setListings] = useState<Listing[]>([]);

  const getListings = async () => {
    setIsLoading(true);
    const data = await fetchListings();
    setListings(data);
    setIsLoading(false);
  }
  // Load data when mounted
  useEffect(() => {
    getListings()
  }, []);

  // Filters

  // Filter: Location Selector
  const [location, setLocation] = useState<string>('');
  const handleChangeLocation = (newLocation: string) => {
    setLocation(newLocation);
  };

  // Filter: Price Range Selector
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);
  useEffect(() => {
    // Find min and max from backend
    const minPrice = Math.min(...listings.map(item => item.price));
    const maxPrice = Math.max(...listings.map(item => item.price));
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    setPriceRange([minPrice, maxPrice]);
  }, [listings]);

  // Filter: View Mode Selector
  type viewModeType = 'list' | 'map';
  const [viewMode, setViewMode] = useState<viewModeType>('list');
  const handleChangeViewMode = (newOption: viewModeType) => {
    setViewMode(newOption);
  };


  // Save to Wishlist
  const onSavedToWishlist = (id: string) => {
    console.log(`Save ${id} to Wishlist`);
    setListings(listings.map(item => {
      if (item._id === id) {
        return {
          ...item,
          isSaved: !item.isSaved,
        };
      }
      return item;
    }));
  };


  const listing_item_breakpoints = {
    xs: viewMode === 'list' ? 12 : 12,
    sm: viewMode === 'list' ? 6 : 12,
    md: viewMode === 'list' ? 4 : 6,
    lg: viewMode === 'list' ? 4 : 6,
    xl: viewMode === 'list' ? 3 : 4,
  };


  return (
    <Container maxWidth={viewMode === 'map' ? false : 'xl'}>
      {/* Filter bar */}
      <Box
        sx={{
          my: 4,
          display: 'flex',
        }}
      >
        {/* Left */}
        <Grid container columns={12} spacing={2} justifyContent="start" alignItems="center">
          <Grid item>
            <LocationSelector
              sx={{ width: 300 }}
              options={districts}
              handleChangeLocation={handleChangeLocation}
            />
          </Grid>
          <Grid item>
            <PriceSelector
              priceRange={priceRange}
              minPrice={minPrice}
              maxPrice={maxPrice}
              handleChangePriceRange={setPriceRange}
            />
          </Grid>
        </Grid>

        {/* Right */}
        <Grid container columns={12} spacing={2} justifyContent="end" alignItems="center">
          <Grid item>
            <ViewSelector
              viewMode={viewMode}
              handleChangeViewMode={handleChangeViewMode}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" startIcon={<AddIcon />}>{t('housingRental.publish')}</Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Divider variant="middle" sx={{ my: 4, mx: 0 }} /> */}

      {/* Listings */}
      <Box
        sx={{
          display: 'flex',
          // minHeight: 'calc(100vh - 64px - 40px - 32px - 32px)',
        }}
      >
        <Box
          className="lisings"
          sx={{
            flex: '1 1 0',
            overflow: 'scroll',
          }}
        >
          { isLoading &&  
            <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          }
          { !isLoading && <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px'}} >
              <Typography variant="subtitle1" color="text.secondary" component="div" mr={2}>
                {listings.length} search results in {location && `${location},`} Berlin, Germany
              </Typography>
              {/* <Typography gutterBottom variant="h5" component="div" mr={2}>
              {t('listings.latestListings')}
              </Typography>
              <Link href="/" underline="always" sx={{display: 'inline-block'}}>
                {t('listings.more')}
              </Link> */}
            </Box>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              columns={12}
            >
              {listings.map((listing, index) => 
                <Grid item {...listing_item_breakpoints} key={index}>
                  <ListingItem listing={listing} onSaved={onSavedToWishlist} />
                </Grid>
              )}
            </Grid>
          </> }
        </Box>

        {viewMode === 'map' && 
          <Box className="listings-map" sx={{ flex: '1 1 0', marginLeft: '20px' }}>
            <MapViewer listings={listings} onSaved={onSavedToWishlist} />
          </Box>
        }
      </Box>
    </Container>
  );
};

export default HousingRental;

const districts = [
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__1",
      "Gemeinde_name": "Reinickendorf",
      "Gemeinde_schluessel": "012",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000012"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__2",
      "Gemeinde_name": "Charlottenburg-Wilmersdorf",
      "Gemeinde_schluessel": "004",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000004"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__3",
      "Gemeinde_name": "Treptow-Köpenick",
      "Gemeinde_schluessel": "009",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000009"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__4",
      "Gemeinde_name": "Pankow",
      "Gemeinde_schluessel": "003",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000003"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__5",
      "Gemeinde_name": "Neukölln",
      "Gemeinde_schluessel": "008",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000008"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__6",
      "Gemeinde_name": "Lichtenberg",
      "Gemeinde_schluessel": "011",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000011"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__7",
      "Gemeinde_name": "Marzahn-Hellersdorf",
      "Gemeinde_schluessel": "010",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000010"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__8",
      "Gemeinde_name": "Spandau",
      "Gemeinde_schluessel": "005",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000005"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__9",
      "Gemeinde_name": "Steglitz-Zehlendorf",
      "Gemeinde_schluessel": "006",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000006"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__10",
      "Gemeinde_name": "Mitte",
      "Gemeinde_schluessel": "001",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000001"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__11",
      "Gemeinde_name": "Friedrichshain-Kreuzberg",
      "Gemeinde_schluessel": "002",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000002"
  },
  {
      "gml_id": "s_wfs_alkis_bezirk.F176__12",
      "Gemeinde_name": "Tempelhof-Schöneberg",
      "Gemeinde_schluessel": "007",
      "Land_name": "Berlin",
      "Land_schluessel": "11",
      "Schluessel_gesamt": "11000007"
  }
];

// const data: Listing[] = [
//   {
//     id: '1',
//     title: 'Comfortable apartment near Alexanderplatz',
//     description: 'This is a nice apartment in Berlin',
//     price: 1000,
//     address: {
//       'street': 'Alexanderplatz',
//       'district': 'Mitte',
//       'zipCode': '10178',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.521918,
//       'longitude': 13.413215,
//     },
//     pictures: [
//       'https://images.unsplash.com/photo-1678382782815-3c2d95418115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1653162113638-44b642b44c31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fGFsZXhhbmRlcnBsYXR6JTIwYmVybGlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1597932526087-f80dc479cba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGFsZXhhbmRlcnBsYXR6JTIwYmVybGlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1638433343422-bf9e2772e441?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGFsZXhhbmRlcnBsYXR6JTIwYmVybGlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
//     ],
//     rooms: 2,
//     area: 50,
//     maxTenants: 2,
//     type: 'apartment',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '2',
//     title: 'A cozy room in a 4-room apart in Wedding',
//     description: '',
//     price: 300,
//     address: {
//       'street': 'Müllerstraße',
//       'district': 'Mitte',
//       'zipCode': '13353',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.54944,
//       'longitude': 13.34111,
//     },
//     pictures: [
//       'https://images.unsplash.com/photo-1648032634537-5e6c0a618ea8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8d2VkZGluZyUyMGJlcmxpbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1633269487357-8eeab97eb113?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8d2VkZGluZyUyMGJlcmxpbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1590741754629-19c9ff101efd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHdlZGRpbmclMjBiZXJsaW58ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'
//     ],
//     rooms: 1,
//     area: 20,
//     maxTenants: 1,
//     type: 'privateRoom',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '3',
//     title: 'WG in Charlottenburg',
//     description: 'This is a WG in Charlottenburg',
//     price: 500,
//     address: {
//       'street': 'Schillerstraße 96',
//       'district': 'Charlottenburg-Wilmersdorf',
//       'zipCode': '10625',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.51075575250985,
//       'longitude': 13.317505242888455,
//     },
//     pictures: [
//       'https://images.unsplash.com/photo-1603232026106-59fb69acbac2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGNoYXJsb3R0ZW5idXJnJTIwcGFsYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1566687504347-797edc52f998?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGNoYXJsb3R0ZW5idXJnfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
//       'https://images.unsplash.com/photo-1635091260914-ca8cb291c7bf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNoYXJsb3R0ZW5idXJnfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
//     ],
//     rooms: 1,
//     area: 20,
//     maxTenants: 1,
//     type: 'privateRoom',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: true,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '4',
//     title: 'A furnished studio in Postdamer Platz',
//     description: 'This is a furnished studio in Postdamer Platz',
//     price: 800,
//     address: {
//       'street': 'Potsdamer Platz 10',
//       'district': 'Mitte',
//       'zipCode': '10785',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.50768723196136,
//       'longitude': 13.377082059113002,
//     },
//     pictures: [
//       'https://images.unsplash.com/photo-1561619970-f392502967ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8cG9zdGRhbWVyJTIwcGxhdHp8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
//       'https://lh5.googleusercontent.com/p/AF1QipNmb754h8HLLgtKToDoDyymUZixkR0ES_tOUsM1=w426-h240-k-no',
//       'https://images.unsplash.com/photo-1561541175-8a56fd9ba554?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cG9zdGRhbWVyJTIwcGxhdHp8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
//     ],
//     rooms: 1,
//     area: 30,
//     maxTenants: 1,
//     type: 'studio',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '5',
//     title: 'Beautiful house near Tempelhofer Feld *new building*',
//     description: 'This is a beautiful house near Tempelhofer Feld',
//     price: 1500,
//     address: {
//       'street': 'Tempelhofer Damm',
//       'district': 'Tempelhof-Schöneberg',
//       'zipCode': '12099',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.46531229420902,
//       'longitude': 13.396280156071112,
//     },
//     pictures: ['https://lh5.googleusercontent.com/p/AF1QipO6X8lhOYvSYAKwBWNXUyFGBLePP9euFnQxwtJw=w408-h543-k-no'],
//     rooms: 3,
//     area: 100,
//     maxTenants: 3,
//     type: 'house',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: true,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '6',
//     title: 'A room in a shared apartment',
//     description: 'This is a room in a shared apartment',
//     price: 400,
//     address: {
//       'street': 'Karl-Marx-Straße',
//       'district': 'Neukölln',
//       'zipCode': '12043',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.477444,
//       'longitude': 13.435621,
//     },
//     pictures: ['https://lh5.googleusercontent.com/p/AF1QipNWxlgxrJ7ymwKCyXlXDKKraROHGHvUoZOdv7qW=w408-h353-k-no'],
//     rooms: 1,
//     area: 20,
//     maxTenants: 1,
//     type: 'privateRoom',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '7',
//     title: 'Fashion studio in center of Pankow',
//     description: 'This is a fashion studio in center of Pankow',
//     price: 600,
//     address: {
//       'street': 'Schönhauser Allee',
//       'district': 'Pankow',
//       'zipCode': '10435',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.535498,
//       'longitude': 13.411657,
//     },
//     pictures: [
//       'https://www.engelvoelkers.com/images/01dd166d-a44a-4057-b621-29f7076753fa/attraktive-kapitalanlage-hausansicht?w=576&h=432',
//       'https://www.engelvoelkers.com/images/0fb167bd-d8a1-4620-ba73-f2d222b72eec/attraktive-kapitalanlage-hinterhof?w=576&h=432'
//     ],
//     rooms: 2,
//     area: 50,
//     maxTenants: 2,
//     type: 'studio',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '8',
//     title: 'Stylish 2,5-room-home in Niederschöneweide, Berlin',
//     description: 'This is a stylish 2,5-room-home in Niederschöneweide, Berlin',
//     price: 1800,
//     address: {
//       'street': 'Britzer Straße',
//       'district': 'Treptow-Köpenick',
//       'zipCode': '12439',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.453579611101425,
//       'longitude': 13.51844998182341,
//     },
//     pictures: ['https://wunderflatsng.blob.core.windows.net/imagesproduction/ygWAYUeu0LxP4ZpKHNqS2-large.jpg'],
//     rooms: 2,
//     area: 80,
//     maxTenants: 2,
//     type: 'apartment',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: true,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',
//   },
//   {
//     id: '9',
//     title: 'Waterside maisonette with terrace in Berlin-Kreuzberg',
//     description: 'This is a waterside maisonette with terrace in Berlin-Kreuzberg',
//     price: 2800,
//     address: {
//       'street': 'Paul-Lincke-Ufer',
//       'district': 'Friedrichshain-Kreuzberg',
//       'zipCode': '10999',
//       'city': 'Berlin',
//       'state': 'Berlin',
//       'country': 'Germany',
//       'latitude': 52.497778,
//       'longitude': 13.437222,
//     },
//     pictures: ['https://wunderflatsng.blob.core.windows.net/imagesproduction/SyoeD68FM-large.jpg'],
//     rooms: 3,
//     area: 120,
//     maxTenants: 3,
//     type: 'apartment',
//     availableFrom: '2021-10-01',
//     availableTo: '2022-10-01',
//     isSaved: false,
//     createdAt: '2021-09-01',
//     updatedAt: '2021-09-01',      
//   }
// ];
