
export interface Listing {
  _id: string;
  title: string;
  address: {
    'street': string;
    'district': string;
    'zipCode': string;
    'city': string;
    'state': string;
    'country': string;
    // 'coordinates': [number, number];
    'latitude': number;
    'longitude': number;
  };
  pictures: string[];
  description: string;
  price: number;
  deposit?: number;
  rooms: number;
  area: number;
  floor?: number;
  maxTenants: number;
  type: ListingType;
  availableFrom: string;
  availableTo: string;
  isSaved: boolean;
  createdAt: string;
  updatedAt: string;
}

export type ListingType = 'apartment' | 'studio' | 'house' | 'privateRoom' | 'sharedRoom';

export function listingTypeMap (type: ListingType) {
  switch (type) {
    case 'apartment':
      return 'Apartment';
    case 'studio':
      return 'Studio';
    case 'house':
      return 'House';
    case 'privateRoom':
      return 'Private Room';
    case 'sharedRoom':
      return 'Shared Room';
    default:
      return 'Unknown';
  }
}
