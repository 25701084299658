import { FC, createContext, useState } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

// CONTEXT
interface AlertProps {
  message: string,
  severity: AlertColor,
  duration: number,
  anchorOrigin?: SnackbarOrigin
}

interface SnackbarContextType {
  addAlert: (alert: AlertProps) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({ addAlert: () => {} });


// PROVIDER

interface SnackbarProviderProps {
  children: React.ReactNode;
}

const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }): JSX.Element => {

  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  const addAlert = (alert: AlertProps = {
    message: '',
    severity: 'info',
    duration: 3000,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center'}
  }) => {
    // const alert = { message, severity, duration, anchorOrigin };
    setAlerts((alerts) => [...alerts, alert]);
    setTimeout(() => setAlerts((alerts) => alerts.slice(1, alerts.length)), 3000);
  }

  return (
    <SnackbarContext.Provider value={{ addAlert }}>
      {children}
      {alerts.map((alert) => (
        <Snackbar
          anchorOrigin={alert.anchorOrigin}
          open={true}
          autoHideDuration={alert.duration}
        >
          <Alert
            sx={{ width: '100%' }}
            elevation={6}
            variant="filled"
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  )
};

export { SnackbarContext, SnackbarProvider };
