import { FC, useEffect, useState, useRef } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';

import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface ViewSelectorProps {
  sx?: SxProps<Theme>;
  viewMode: "list" | "map";
  handleChangeViewMode: (newOption: "list" | "map") => void;
};

const ViewSelector: FC<ViewSelectorProps> = ({ sx=[], viewMode, handleChangeViewMode }): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    newOption: "list" | "map",
  ) => {
    if (newOption !== null) {
      handleChangeViewMode(newOption);
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={viewMode}
      exclusive
      onChange={handleClick}
      aria-label="view mode"
      sx={[
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <ToggleButton value="list" sx={{ textTransform: 'none', px: 1.5 }}>
        <ListIcon sx={{ marginRight: 0.5 }} />
        {t('housingRental.viewMode.list')}
      </ToggleButton>
      <ToggleButton value="map" sx={{ textTransform: 'none', px: 1.5 }}>
        <MapIcon sx={{ marginRight: 0.5 }} />
        {t('housingRental.viewMode.map')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
};

export default ViewSelector;
