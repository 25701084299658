
import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import Parallax from 'components/common/Parallax/Parallax';
// import BRANDENBERG_GATE from 'assets/brandenberg_gate.jpg';


const Home: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pages = [
    { 
      "name": t('navbar.housingRental'),
      "path": "housing-rental",
      "disabled": false
    },
    {
      "name": t('navbar.secondHand'),
      "path": "second-hand",
      "disabled": true
    }
  ];

  return (
    <Container disableGutters maxWidth={false} sx={{ position: 'absolute', top: 0 }}>
      <Parallax
        image="https://images.unsplash.com/photo-1642949069177-5852d6c5ce30?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1674&q=80"
        title='OneKo'
        subtitle={`${t('home.subtitle')}`}
        fixed={true}
      >
        <Box mt={'60px'}>
          {pages.map((page) => (
            <Button
              variant="outlined"
              key={page.path}
              disabled={page.disabled}
              onClick={() => navigate(page.path)}
              sx={{ mx: 1 }}
              color="inherit"
              size="large"
            >
              {page.name}
            </Button>
          ))}
        </Box>
      </Parallax>

      <Box 
        sx={{
          bgcolor: '',
          height: '50vh'
        }}
      >
        <Box sx={{
          mx: '80px',
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography flexBasis={'40%'} variant="h3" fontSize={60} fontWeight={800} display={'flex'} alignItems={'center'}>
            {t('home.about.heading')}
          </Typography>
          <Typography flexBasis={'60%'} variant="body1" fontSize={28} display={'flex'} alignItems={'center'}>
            <Trans i18nKey='home.about.description' />
          </Typography>
        </Box>
      </Box>

      <Box 
        sx={{
          bgcolor: '#9EB3C2',
          height: '50vh'
        }}
      >
        <Box sx={{
          mx: '80px',
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography flexBasis={'40%'} variant="h3" fontSize={60} fontWeight={800} display={'flex'} alignItems={'center'}>
            {t('home.services.heading')}
          </Typography>
          <Typography flexBasis={'60%'} variant="body1" fontSize={28} display={'flex'} alignItems={'center'}>
            <Trans i18nKey='home.services.description' />
          </Typography>
        </Box>
      </Box>

      {/* <Box 
        sx={{
          bgcolor: '',
          height: '50vh'
        }}
      >
        <Box sx={{
          mx: '80px',
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography flexBasis={'40%'} variant="h3" fontSize={60} fontWeight={800} display={'flex'} alignItems={'center'}>
            {t('home.joinUs.heading')}
          </Typography>
          <Typography flexBasis={'60%'} variant="body1" fontSize={28} display={'flex'} alignItems={'center'}>
            <Trans i18nKey='home.joinUs.description' />
          </Typography>
        </Box>
      </Box> */}
    </Container>
  );
}

export default Home;
