import { Outlet } from 'react-router-dom';
import NavBar from 'components/common/Navbar/Navbar'
// import Container from '@mui/material/Container';

export default function AppLayout() {
  return (
    <>
      <NavBar />
      {/* <Container maxWidth={false}>
        <Outlet />
      </Container> */}
      <Outlet />
    </>
  );
}
